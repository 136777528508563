import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes as Switch,
} from "react-router-dom";
import Navigation from "../components/navigation/Navigation";
import NotificationBanner from "../components/banner/NotificationBanner";
import GATrackingPageView from '../components/gtag/GATrackingPageView';
import BackToTop from "../components/back-to-top/BackToTop";
import ScrollToTop from "../components/back-to-top/ScrollToTop";
import About from "./about/About";
import Market from "./market/Market";
import TabView from "./own/TabView";
import Minting from "./minting/Minting";
import MintInstructions from "./instructions/MintInstructions";
import ChimpDetails from "./chimp-details/ChimpDetails";
import Chimpay from "./chimpay/ChimpayContainer";
import ChimpayMain from "./chimpay/ChimpayMain";
import ChimpayFAQs from "../components/chimpay/ChimpayFAQ";
import ChimpayDashboard from "./chimpay/Dashboard";
import Admin from "./admin/Admin";
import Landing from "./landing/Landing";
import DiscordRedirected from "./discord/DiscordRedirected";
import ModalProvider from "../providers/ModalProvider";
import ShopContainer from "./shop/ShopContainer";
import RedeemGift from "./shop/RedeemGift";
import ShopDetails from "./shop-details/ShopDetails";
import ShopCheckout from "./shop-checkout/ShopCheckout";
import Orders from "./orders/Orders";
import Statement from "./statement/Statement";
import OrderDetail from "./orders/OrderDetail";
import TermOfUse from "./termOfUse/TermOfuse";
import BananaScrolls from "./lore/BananaScrolls";
import PlayList from "./lore/PlayList";
import useInitiateData from "../hooks/useInitiateDataHook";
import {
  HIDE_MARKETPLACE,
  HIDE_NAV,
  HIDE_MINT,
  HIDE_MERCHANDISE,
} from "../configs";
import styles from "./Routes.module.scss";
import PrivacyPolicy from "./policy/PrivacyPolicy";

const Routes = () => {
  useInitiateData();

  return (
    <Router>
      <GATrackingPageView />
      <ModalProvider>
        <BackToTop />
        <ScrollToTop />
        {!HIDE_NAV && <Navigation />}
        <NotificationBanner />
        <div className={styles.container}>
          <Switch>
            <Route path="/" element={<Landing />} />
            <Route path="/admin" element={<Admin />} />
            {!HIDE_MINT && <Route path="/mint-chimp" element={<Minting />} />}
            <Route path="/my-chimps" element={<TabView />} />
            {!HIDE_MARKETPLACE && <Route path="/market" element={<Market />} />}
            {HIDE_MARKETPLACE && (
              <Route path="/faq" element={<MintInstructions />} />
            )}
            <Route path="/chimp/:id" element={<ChimpDetails />} />
            <Route
              path="/discord/oauth-redirect"
              element={<DiscordRedirected />}
            />
            <Route path="/chimpay" element={<Chimpay />}>
              <Route path="" element={<ChimpayMain />} />
              <Route path="faqs" element={<ChimpayFAQs />} />
              <Route path="dashboard" element={<ChimpayDashboard />} />
              <Route path="scroll" element={<BananaScrolls />} />
              <Route path="playlist/:id" element={<PlayList />} />
            </Route>
            <Route path="/about" element={<About />} />
            {!HIDE_MERCHANDISE && (
              <>
                <Route path="/shop" element={<ShopContainer />} />
                <Route path="/shop/redeem-gift" element={<RedeemGift />} />
                <Route path={`/shop/details/:id`} element={<ShopDetails />} />
                <Route path={"/shop/checkout"} element={<ShopCheckout />} />
                <Route path={"/shop/orders"} element={<Orders />} />
                <Route path={`/shop/orders/:id`} element={<OrderDetail />} />
              </>
            )}
            <Route path="/terms" element={<TermOfUse />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/statement" element={<Statement />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Switch>
        </div>
      </ModalProvider>
    </Router>
  );
};

export default Routes;
